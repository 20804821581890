
























































































import {
	Component,
	Prop,
	Vue,
	Watch
} from "vue-property-decorator";
import {
	apiUserInfo,
	apiUserInviterLists
} from "@/api/user/user";
import {
	RequestPaging
} from "@/utils/util"
import LsPagination from "@/components/ls-pagination.vue"
import FansPane from '@/components/user/fans-pane.vue'
import ExportData from '@/components/export-data/index.vue'
@Component({
	components: {
		LsPagination,
		FansPane,
		ExportData
	},
})
export default class LsFanList extends Vue {
	/** S Data **/
	isNameSN = '' // 0-编号， 1-昵称
	// 邀请人信息
	inviter	= {
		name: '', // 邀请人名称
		num: '', // 我邀请的人数
	}
	
	// 查询表单
	form = {
		user_id: '', // 用户id
		sn: '', // 用户编号
		nickname: '', // 用户昵称
	}
	pager: RequestPaging = new RequestPaging()
	
	apiUserInviterLists = apiUserInviterLists
	/** E Data **/
	
	// 监听用户信息查询框条件
	@Watch('isNameSN', {
		immediate: true
	})
	getChange(val: any) {
		// 初始值
		this.form.sn = ''
		this.form.nickname = ''
		// this.form.mobile = ''
	}
	
	/** S Methods **/
	// 重置
	onReset() {
		this.form.sn = ''
		this.form.nickname = ''
		this.getList()
	}
	
	// 用户信息
	getUserInfo() {
		apiUserInfo({user_id: this.form.user_id})
		.then((res: any) => {
			console.log(res)
			this.inviter = res
		})
	}
	// 邀请列表
	getList(): void {
		this.pager
			.request({
				callback: apiUserInviterLists,
				params: {
					...this.form,
				},
			})
			.then((res: any) => {
				console.log(this.pager, "this.pager")
				console.log(res, "res")
			})
	}
	/** E Methods **/

	/** S Life Cycle **/
	created() {
		const query: any = this.$route.query
		if (query.id) this.$set(this.form, 'user_id', query.id)
		
		setTimeout( ()=> {
			this.getUserInfo()
			this.getList()
		}, 50)
		
	}
	/** E Life Cycle **/
}
